<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <TMessage content="Fund account" bold />
        <TSpinner :loading="fetching" />
        <TButtonEdit
          @click="showModalFund = true"
          variant="outline"
          class="ml-auto"
        />
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="12" md="3">
            <CCallout color="info">
              <TMessage
                content="Accounting account"
                bold
                color="info"
                uppercase
              />
              <SMessageAccount :value="fund.account_id" class="ml-1 h5" />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="info">
              <TMessage content="Currency" bold color="info" uppercase />
              <TMessage :content="fund.currency_id" class="h5" noTranslate />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="primary">
              <TMessage content="Balance" bold color="primary" uppercase />
              <TMessageMoney
                :amount="fund.balance"
                class="h5"
                :currency="fund.currency_id"
              />
            </CCallout>
          </CCol>
        </CRow>
      </CCardBody>
      <FundModal :show.sync="showModalFund" :fund="fund" />
    </CCard>

    <TableTransactionsAccount
      v-if="fund.account_id"
      class="mb-4"
      :account_id="fund.account_id"
      store="accounting.transactions"
      :default_filter="{
        'filter[debit_or_credit]': fund.account_id,
        'filter[currency_id]': fund.currency_id,
      }"
      :key="`table-transactions-${id}`"
    />
  </div>
</template>

<script>
import TableTransactionsAccount from "../../../components/TableTransactionsAccount.vue";
import { mapGetters } from "vuex";
import FundModal from "./FundModal.vue";

export default {
  components: { TableTransactionsAccount, FundModal },
  data() {
    return {
      showModalFund: false,
    };
  },
  created() {
    this.$store.dispatch("accounting.funds.detail.fetch", this.id);
  },
  destroyed() {
    this.$store.commit("accounting.funds.detail.purge");
  },
  computed: {
    ...mapGetters({
      fund: "accounting.funds.detail",
      fetching: "accounting.funds.detail.fetching",
    }),
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
