<template>
  <TModal
    title="Fund"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :creating="loading"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <SSelectAccount
      label="Account aggregation"
      class="mb-3"
      :prepend="[]"
      :value.sync="data.account_id"
      @update:value="input.account_id = $event"
      noFetchStore
    />
    <SSelectCurrency
      v-if="!is_edit"
      label="Currency"
      :prepend="[]"
      class="mb-3"
      :value.sync="input.currency_id"
      @update:value="input.currency_id = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fund: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    id: "",
    account_id: "",
    currency_id: "",
  },
  data() {
    return {
      options: [
        { value: "user", label: "Customer" },
        { value: "supplier", label: "Supplier" },
      ],
      loading: false,
      data: this.getInput(),
    };
  },
  watch: {
    fund() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.fund) {
        return false;
      }
      return !!this.fund.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("accounting.funds.create", this.input)
        .then(() => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store
        .dispatch("accounting.funds.detail.update", {
          name: this.input.name,
          account_id: this.input.account_id,
        })
        .then((data) => {
          if (data.bank_id)
            this.$store.dispatch("accounting.funds.detail.fetch", data.id);
          this.$emit("update:show", false);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return { ...this.fund };
    },
  },
};
</script>
